var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex } from '../../common/components/Flex';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import { textWithEllipsis } from '../../common/theme/helper';
import { DownArrow, RightChevron } from './Arrow';
import { ActiveTab, InactiveTab } from './Tab';
var TabBar = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style-type: none;\n"], ["\n  list-style-type: none;\n"])));
TabBar.defaultProps = {
    as: 'ul',
    width: 1,
    p: 0,
    m: 0,
};
var TabItem = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
TabItem.defaultProps = {
    as: 'li',
    flex: 1,
};
var TabLabel = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textWithEllipsis());
var CommonTabBar = function (_a) {
    var activeBackgroundColor = _a.activeBackgroundColor, activeTextColor = _a.activeTextColor, items = _a.items, dyDecisionId = _a.dyDecisionId, dyVariationId = _a.dyVariationId, placement = _a.placement, name = _a.name;
    var _b = usePromoEvents({
        dyDecisionId: dyDecisionId,
        dyVariationId: dyVariationId,
        placement: placement,
        name: name,
    }), onPromoView = _b.onPromoView, onPromoClick = _b.onPromoClick;
    useEffect(function () {
        if (!name) {
            return;
        }
        onPromoView();
    }, [onPromoView]);
    return (React.createElement(TabBar, null, items.map(function (_a, index) {
        var isActive = _a.isActive, text = _a.text, link = _a.link;
        return (React.createElement(TabItem, { key: index }, isActive ? (React.createElement(ActiveTab, { backgroundColor: activeBackgroundColor, color: activeTextColor },
            React.createElement(TabLabel, null, text),
            React.createElement(DownArrow, { borderTopColor: activeBackgroundColor }))) : (React.createElement(InactiveTab, { key: index, href: link, onClick: name ? onPromoClick : undefined },
            React.createElement(TabLabel, null, text),
            React.createElement(RightChevron, null)))));
    })));
};
CommonTabBar.defaultProps = {
    activeTextColor: 'sys.neutral.text.strong',
    activeBackgroundColor: 'sys.neutral.background.hover',
};
export default CommonTabBar;
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { borderColor, borderStyle, bottom, minWidth, position, right } from 'styled-system';
import Icon from '../../common/components/Icon';
import { borderBottomWidth, borderLeftWidth, borderRightWidth, borderTopColor, borderTopWidth, fill, translate3d, } from '../../common/theme/system-utilities';
var RIGHT_CHEVRON_SIZE = 24;
export var DownArrow = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, bottom, right, borderLeftWidth, borderRightWidth, borderTopWidth, borderBottomWidth, borderColor, borderTopColor, borderStyle, translate3d);
DownArrow.defaultProps = {
    bottom: '0',
    right: '50%',
    position: 'absolute',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderTopColor: 'sys.neutral.background.hover',
    borderBottomWidth: 0,
    borderRightWidth: ['12px', null, '18px'],
    borderLeftWidth: ['12px', null, '18px'],
    borderTopWidth: ['8px', null, '12px'],
    translate3d: ['12px, 11px, 0', null, '18px, 15px, 0'],
    role: 'presentation',
};
export var RightChevron = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), minWidth, fill);
RightChevron.defaultProps = {
    size: RIGHT_CHEVRON_SIZE,
    minWidth: RIGHT_CHEVRON_SIZE,
    fill: 'sys.neutral.text.strong',
    role: 'presentation',
    name: 'arrowRight',
};
var templateObject_1, templateObject_2;

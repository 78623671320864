var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@emotion/styled';
import { alignItems, backgroundColor, border, borderBottom, borderLeft, borderRight, color, display, flex, fontWeight, justifyContent, minHeight, position, space, textAlign, } from 'styled-system';
import { LinkWithIcon } from '../../common/components/Link';
import { boxSizing, borderBottomColor, } from '../../common/theme/system-utilities';
export var InactiveTab = styled(LinkWithIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), boxSizing, minHeight, textAlign, backgroundColor, color, space, flex, justifyContent, alignItems, display, borderBottom, border, borderRight, borderLeft, borderBottomColor);
InactiveTab.defaultProps = {
    styled: true,
    fontSize: ['fs_16', null, 'fs_18', 'fs_20'],
    lineHeight: ['lh_20', null, 'lh_22', 'lh_24'],
    minHeight: '56px',
    boxSizing: 'border-box',
    borderBottom: 'sys.neutral.text.strong',
    borderRight: 'none',
    borderLeft: 'none',
    border: '3px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderBottomColor: 'sys.neutral.text.strong',
    color: 'sys.neutral.text.strong',
    p: 'sp_8',
    flex: 1,
};
export var ActiveTab = styled(InactiveTab)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fontWeight, position);
ActiveTab.defaultProps = __assign(__assign({}, InactiveTab.defaultProps), { styled: false, borderBottomColor: 'transparent', position: 'relative', fontWeight: 'bold', as: 'span' });
var templateObject_1, templateObject_2;
